import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Grid from "components/grid"
import {StaticImage} from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"
import constants from "../../constants"
import InlineVideo from "../../components/inline-video"
import RoundedBtn from "../../components/rounded-btn"
import {Hand, Smile, Eyes} from "../../components/icons"

const SS22Landing = ({location}) => {
    const title3 = `text-[24px] lg:text-[80px]`
    const captionNumber = `${constants.caption} leading-none pr-4`
    const filepath = `../../../static/images/ss22/`
    const captionIconicEvolution = (
        <div className={`${constants.caption} pt-1 lg:pt-4 pr-0 lg:pr-10 flex w-10/12`}>
            <div className={`${captionNumber}`}>1</div>
            <div>
                ECCO Hybrid Pot Bag in Shocking Pink<br/>
                ECCO Pot Bag Mini in Light Sky<br/>
                ECCO Circular Patch Mini Pot in Sea Port/Cloud/Shocking Pink
            </div>
        </div>
    )
    const captionInovativePhilosophy = (
        <div className={`${constants.caption} pt-1 lg:pt-4 pr-0 lg:pr-10`}>
            Our philosophy is all about innovative yet organic materials.​
            <br/><br/>
            Capturing, discovering and appreciating their beauty in its pure form. Enjoy the close-ups and sculptural play created as a three-way dialogue with our SS22 collection and the epic ECCO Leather tannery in Dongen.​
        </div>
    )
    const captionCassetteBagMini = (
        <div className={`${constants.caption} pt-1 lg:pt-4 pr-0 lg:pr-10 flex`}>
            <div className={`${captionNumber}`}>3</div>
            <div>
                ECCO Cassette Bag Mini in Festival Fuchsia<br/>
                ECCO Cassette Bag Compact in Pale Banana<br/>
                ECCO Cassette Bag Full Size in Festival Fuchsia
            </div>
        </div>
    )
    const captionCeramicLeather = (
        <div className={`${constants.caption} pt-1 lg:pt-2 pr-1 lg:pr-10 lg:float-right`}>
            <div className={`lg:w-full`}>
                Ceramic Leather<br/>
                Sensuous, textured, inspired by our Danish roots.
            </div>
        </div>
    )
    const captionCeramicLeather2 = (
        <div className={`${constants.caption} lg:absolute pt-2 lg:pt-2 px-2 lg:px-0 flex bottom-2 right-2`}>
            <div className={`${captionNumber}`}>5</div>
            <div>ECCO Ceramic Tech-Bag Compact in Black</div>
        </div>
    )
    const seasonShortsTitle = `Season 2 Shorts`
    const seasonShortsDescription = `ECCO Leather Goods is back for SS22 with a functional and playful collection. This time around you can discover our collection through our series of mini collection videos and editorial images starring our team.`
    return (
        <Layout location={location}>
            <SEO title={`SS22 Collection`} pathname={location.pathname}/>

            <div className={`w-full h-full text-[#182A1F]`} id={`s0`}>

                <div className={`!bg-[#FCF2B1] pt-32 lg:pt-44 pb-10 lg:pb-16 text-center w-full flex flex-col items-center justify-center`} id={`s1`}>
                    <div className={`w-full`}>
                        <div data-scroll data-scroll-target="#s1" className={`text-[38px] lg:text-[160px] !leading-none relative z-10`}>Tools to<br/>Live Freely</div>
                        <div data-scroll data-scroll-target="#s1" data-scroll-speed="2" className={`px-8 lg:px-24`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_070_335.jpg`}
                                objectFit={`cover`}
                                alt={``}
                                className={`w-full lg:w-5/12 relative z-0 -mt-10 lg:-mt-40 mb-2 lg:mb-10`}
                            />
                        </div>
                        <div data-scroll data-scroll-target="#s1" data-scroll-speed="1" className={`text-[22px] lg:text-[32px] !leading-tight relative z-10`}>Functional and playful leather goods for a brave new world.</div>
                    </div>
                </div>

                <div className={`!bg-[#C7317C] w-full`}>
                    <Marquee
                        gradient={false}
                        speed={50}
                    >
                        <div className={`text-[#FCF2B1] text-[28px] lg:text-[60px] leading-none lg:leading-tight flex items-center pt-3 lg:pt-2 pb-4 lg:pb-4 whitespace-nowrap`}>
                            <div>&nbsp;ECCO Leather Goods – SS22&nbsp;</div>
                            <Hand className={`w-[25px] lg:w-[38px] mx-2 lg:mx-4`}/>
                            <div>&nbsp;ECCO Leather Goods – SS22 –</div>
                        </div>
                    </Marquee>
                </div>

                <div className={`!bg-[#D5E9E2] w-full text-center relative`}>
                    <div className={`px-8 lg:px-0 w-full lg:w-10/12 text-[24px] lg:text-[50px] !leading-tight mx-auto pt-14 pb-16 lg:pt-32 lg:pb-52`}>
                        For our second season ECCO Leather Goods presents Tools To Live Freely, building on our brand’s debut with functional and playful accessories for optimistic New Utopians.
                    </div>
                    <div className={`flex justify-center lg:flex-none pb-16 lg:pb-0 relative`}>
                        <div className={`lg:absolute z-10 lg:left-1/2 w-[214px] h-[214px] lg:h-[360px] lg:w-[360px] lg:bottom-0 transform lg:-translate-x-1/2 lg:translate-y-1/2`}>
                            <Smile
                                className={`fill-[#C7317C] relative z-10`}
                            />
                        </div>
                    </div>
                </div>

                <div className={`bg-[#182A1F] w-full px-6 lg:px-10 relative pt-12 lg:pt-60 pb-14 lg:pb-12 text-[#FCF2B1]`}>
                    <div className={`w-10/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>Iconic Evolution</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-11/12 lg:w-8/12`}>The Pot Bag in maxi and mini.<br/>To collect and combine.</div>
                    </div>
                </div>
                <div className={`w-full h-full text-[#FCF2B1] bg-[#182A1F]`}>
                    <Grid className={`px-0 lg:px-10`}>
                        <div className={`hidden lg:block col-start-1 col-end-6 mt-52`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_130_SCULPTURES_465.jpg`}
                                alt={``}
                            />
                            {captionInovativePhilosophy}
                        </div>
                        <div className={`hidden lg:block col-start-6 col-end-13 -mt-24 pl-24`}>
                            <div className={`relative`}>
                                <StaticImage
                                    src={`${filepath}ECCO_210709_TCRISTIANI_020_246.jpg`}
                                    alt={``}
                                />
                            </div>
                            {captionIconicEvolution}
                        </div>
                        <div className={`lg:hidden col-start-1 col-end-13 px-10`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_020_246.jpg`}
                                alt={``}
                            />
                            {captionIconicEvolution}
                        </div>
                        <div className={`lg:hidden col-start-1 col-end-13 px-6 my-32`}>
                            <div className={`flex gap-4`}>
                                <StaticImage
                                    src={`${filepath}ECCO_210709_TCRISTIANI_130_SCULPTURES_465.jpg`}
                                    alt={``}
                                />
                                <div className={`flex justify-end items-end`}>
                                    {captionInovativePhilosophy}
                                </div>
                            </div>
                        </div>
                        <div className={`px-6 lg:px-0 col-start-2 col-end-12 lg:col-start-8 lg:col-end-12 mt-0 lg:mt-22 mb-16 lg:mb-24`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_120_USINE_514.jpg`}
                                alt={``}
                            />
                            <div className={`${constants.caption} pt-1 lg:pt-4 pr-0 lg:pr-10`}>
                                Captured in the ECCO Leather tannery: the hydraulic press. It’s purpose is to imprint distinct patterns on the grain of our leathers. We love it’s heavy vibe.​
                            </div>
                        </div>
                        {/* <div className={`px-6 lg:px-0 col-start-1 col-end-13 lg:col-start-7 lg:col-end-12 mt-16 lg:mt-32 mb-16 lg:mb-20`}>
              <InlineVideo
                src="UTOPIA.mp4"
              />
              <p className={`${constants.caption} pt-1 lg:pt-2`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div> */}
                    </Grid>
                </div>
                <div className={`bg-[#E5E2C9] w-full px-6 lg:px-10 relative pt-12 lg:pt-60 pb-10 lg:pb-12 text-[#182A1F]`}>
                    <div className={`w-11/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>Circular Design</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-11/12 lg:w-8/12`}>Jewelry-like mini bags patched together with leather off-cuts.</div>
                    </div>
                </div>
                <Grid className={`bg-[#E5E2C9]`}>
                    <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-8 px-6 lg:px-0`}>
                        <div className={`relative`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_040_272.jpg`}
                                alt={``}
                            />
                        </div>
                        <div className={`${constants.caption} pt-1 lg:pt-4 px-3 pl-0 lg:pl-6 flex`}>
                            <div className={`${captionNumber}`}>2</div>
                            <div>
                                ECCO Circular Patch Mini Pot in Kombu Green/Soft Mint/Cloud / cloud<br/>
                                ECCO Dreamer Patch Mini in Kombu Green/Soft Mint/Cloud
                            </div>
                        </div>
                    </div>
                    <div className={`col-start-1 col-end-13 lg:col-start-8 lg:col-end-13 -mt-0 lg:-mt-72`}>
                        <div className={`flex flex-col space-y-14 lg:space-y-20`}>
                            <div className={`px-6 lg:px-10 mt-14 lg:mt-0`}>
                                <div className={`w-9/12 mx-auto lg:mx-0 lg:w-10/12 lg:float-right`}>
                                    <StaticImage
                                        src={`${filepath}ECCO_210709_TCRISTIANI_120_USINE_USINE_441.jpg`}
                                        alt={``}
                                    />
                                    <div className={`${constants.caption} pt-1 lg:pt-4`}>
                                        Our spray cabin, seen through the solarizing lense à la Man Ray. Here we lay foundational coats onto the leathers. For example to build up our fine-tuned rich colours.​
                                    </div>
                                </div>
                            </div>
                            <div className={`lg:pl-20`}>
                                <div className={`w-10/12 mx-auto lg:mx-0 lg:w-full lg:float-right`}>
                                    <StaticImage
                                        src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_161.jpg`}
                                        alt={``}
                                    />
                                    <div className={`${constants.caption} pt-1 lg:pt-4`}>
                                        Birth of new textures and surfaces.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`mb-16 lg:mb-60`}></div>
                    {/* <div className={`col-start-2 col-end-12 lg:col-start-4 lg:col-end-10 mt-32 mb-32 lg:mb-20`}>
            <InlineVideo
              src="UTOPIA.mp4"
            />
            <p className={`${constants.caption} pt-1 lg:pt-2`}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div> */}
                </Grid>
                <div className={`bg-[#1A2C39] w-full px-6 lg:px-10 relative pt-12 lg:pt-52 pb-6 lg:pb-28 text-[#E5E2C9] flex flex-col-reverse lg:flex-row`}>
                    <div className={`w-full lg:w-7/12 pt-6 lg:pt-0 pb-10 lg:pb-0`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>Cassette</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-full`}>
                            Crafted from a combination of smooth and pebbled leathers, the ECCO Cassette Bags are a series of lightweight accessories with a curved base and back slip pockets. A magnetic flap lifts to reveal the inner space.
                            Adjust the webbed shoulder strap to wear in your custom style.
                        </div>
                    </div>
                    <div className={`w-full lg:w-5/12 flex justify-end items-end lg:justify-center lg:items-center lg:mt-0 pr-10 lg:pr-0`}>
                        <Hand
                            className={`fill-[#E5E2C9] w-[132px] lg:w-[290px]`}
                        />
                    </div>
                </div>
                <Grid className={`bg-[#1A2C39] text-[#E5E2C9]`}>
                    <div className={`lg:hidden col-start-1 col-end-13 px-6 mb-20`}>
                        <div className={`relative`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_030_254.jpg`}
                                alt={``}
                            />
                        </div>
                        {captionCassetteBagMini}
                    </div>
                    <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-6 pl-0 lg:pl-10 mt-0 lg:mt-72`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_120_USINE_USINE_410.jpg`}
                            alt={``}
                        />
                        <div className={`${constants.caption} pt-2 lg:pt-4 px-4 lg:px-0`}>
                            The epic chain conveyor at the Dongen tannery. It spans the entire space – complete lots of leather travel in it through our hall. Going through different zones of temperature. An iconic, meditative way of drying
                            leather.​
                        </div>
                    </div>
                    <div className={`col-start-1 col-end-13 lg:col-start-7 lg:col-end-13 mt-12 lg:mt-0`}>
                        <div className={`flex flex-col space-y-0 lg:space-y-10 justify-end items-end`}>
                            {/* <div className={`px-10 lg:px-10 w-full lg:w-10/12`}>
                <InlineVideo
                  src="UTOPIA.mp4"
                />
                <p className={`${constants.caption} pt-1 lg:pt-2`}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div> */}
                            <div className={`hidden lg:block mt-28 lg:-mt-0 z-10 w-8/12 lg:w-full`}>
                                <div className={`relative`}>
                                    <StaticImage
                                        src={`${filepath}ECCO_210709_TCRISTIANI_030_254.jpg`}
                                        alt={``}
                                    />
                                </div>
                                {captionCassetteBagMini}
                            </div>
                            <div className={`w-full lg:pr-10 lg:w-10/12`}>
                                <StaticImage
                                    src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_149.jpg`}
                                    alt={``}
                                />
                                <p className={`${constants.caption} pt-2 lg:pt-4 px-4 lg:px-0 text-[#1A2C39]`}>
                                    Free Form: pure beauty of naturally grown materials – like continents.​
                                </p>
                            </div>
                        </div>
                    </div>
                </Grid>
                <div className={`bg-[#FCF1A8] w-full pt-28 lg:pt-12 pb-10 lg:pb-0 text-[#1A2C39] -mt-16 lg:-mt-40`}>
                    <div className={`w-full lg:w-5/12 pb-20 lg:pb-40 px-6 lg:px-10`}>
                        <div className={`text-[18px] lg:text-[28px] leading-tight `}>A completely fresh, yet authentic mix of inspirations that feels playful, colorful and happy to be alive.</div>
                    </div>
                    <Grid className={`mt-4 lg:mt-40 h-full`}>
                        <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-8 px-6 lg:px-10`}>
                            <Eyes className={`fill-[#1A2C39] w-[160px] lg:w-[360px] pb-10 lg:pb-32`}/>
                            <div className={`lg:hidden text-[28px] leading-tight mb-6`}>ECCO Leather Goods is determined to explore its Danish roots.</div>
                            <div className={`lg:hidden col-start-1 col-end-13`}>
                                <StaticImage
                                    src={`${filepath}DEF_ERIC-7.jpg`}
                                    alt={``}
                                    className={`h-full`}
                                />
                            </div>
                            <div className={`text-[32px] lg:text-[80px] leading-tight lg:leading-none mt-4 lg:mt-0`}>{seasonShortsTitle}</div>
                            <div className={`text-[14px] lg:text-[28px] leading-tight lg:pr-20 mt-6 lg:mt-14`}>{seasonShortsDescription}</div>
                            <div className={`lg:inline-block pb-4 lg:pb-20`}>
                                <RoundedBtn
                                    to={`/collage`}
                                    className={`!bg-[#1A2C39] !text-[#FCF1A8] px-6 lg:px-12 py-3 lg:py-4 flex w-9/12 lg:w-auto mt-8 lg:mt-12 !text-[14px] lg:!text-[22px]`}
                                >Discover</RoundedBtn>
                            </div>
                        </div>
                        <div className={`hidden lg:block col-start-1 col-end-13 lg:col-start-8 lg:col-end-13`}>
                            <StaticImage
                                src={`${filepath}DEF_ERIC-7.jpg`}
                                alt={``}
                                className={`h-full`}
                            />
                        </div>
                    </Grid>
                </div>
                <div className={`bg-[#E6D1D0] w-full px-6 lg:px-10 relative pt-12 lg:pt-40 pb-6 lg:pb-12 text-[#1A2C39]`}>
                    <div className={`w-10/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>The Bento Pack</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-11/12 lg:w-8/12`}>To wear or carry,<br/>your way.</div>
                    </div>
                </div>
                <Grid className={`bg-[#E6D1D0] pb-14 lg:pb-32 text-[#1A2C39]`}>
                    <div className={`col-start-1 col-end-13 lg:col-start-4 lg:col-end-10 relative z-20 mb-10 lg:mb-24`}>
                        <div className={`relative`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_100_368.jpg`}
                                alt={``}
                            />
                        </div>
                        <div className={`${constants.caption} pt-2 lg:pt-2 px-2 lg:px-0 flex`}>
                            <div className={`${captionNumber}`}>4</div>
                            <div>
                                ECCO Bento Pack in Seaport
                            </div>
                        </div>
                    </div>
                    <div className={`col-start-2 col-end-12 px-6 lg:px-0 lg:col-start-2 lg:col-end-6 mt-2 lg:mt-32 relative z-10`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_130_SCULPTURES_491.jpg`}
                            alt={``}
                        />
                        <p className={`${constants.caption} pt-1 lg:pt-2`}>
                            Walk before you run
                        </p>
                    </div>
                    <div className={`col-start-2 col-end-12 px-12 lg:px-0 lg:col-start-7 lg:col-end-13 mt-16 lg:mt-0 relative z-0`}>
                        <div className={`pr-0 lg:pr-10`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_120_USINE_397.jpg`}
                                alt={``}
                            />
                            <p className={`${constants.caption} pt-1 lg:pt-2`}>
                                Buffing creates a new type of leather when grinding off the grain – the very top layer of the leather. This is how we create the ‘napa effect’ to give a velvet-like feeling.
                            </p>
                        </div>
                    </div>
                    {/* <div className={`col-start-2 col-end-12 lg:col-start-3 lg:col-end-8 mt-16 lg:mt-0`}>
            <div className={`pr-0 lg:pr-10`}>
              <InlineVideo
                src="UTOPIA.mp4"
              />
              <p className={`${constants.caption} pt-1 lg:pt-2`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div> */}
                </Grid>
                <div className={`hidden lg:block`}>
                    <StaticImage
                        src={`${filepath}ecco-pattern.jpg`}
                        alt={``}
                    />
                </div>
                <div className={`lg:hidden`}>
                    <StaticImage
                        src={`${filepath}ecco-pattern-mobile.jpg`}
                        alt={``}
                    />
                </div>

                <div className={`bg-[#1A2C39] w-full px-6 lg:px-10 relative pt-20 lg:pt-52 pb-20 lg:pb-12 text-[#FCF1A8]`}>
                    <div className={`w-10/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>The Tech Bag</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-10/12 lg:w-8/12`}>A modern interpretation of a back-to-work essential.</div>
                    </div>
                </div>

                <Grid className={`bg-[#1A2C39] pb-12 lg:pb-32 text-[#FCF1A8]`}>
                    <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 relative z-0`}>
                        <div className={`lg:hidden pb-14 z-10 top-0 w-full`}>
                            <div className={`w-full lg:w-6/12`}>
                                <div className={`relative`}>
                                    <StaticImage
                                        src={`${filepath}ECCO_210709_TCRISTIANI_050_286.jpg`}
                                        alt={``}
                                    />
                                    {captionCeramicLeather2}
                                </div>
                            </div>
                        </div>
                        <div className={`lg:hidden relative z-0 px-6 lg:px-0`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_218ceramic-leather.jpg`}
                                alt={``}
                            />
                            {captionCeramicLeather}
                        </div>
                        <div className={`hidden lg:block relative z-0 px-6 lg:px-0`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_218-desktop.jpg`}
                                alt={``}
                            />
                            {captionCeramicLeather}
                        </div>
                        <div className={`hidden lg:flex pb-14 lg:absolute z-10 top-0 w-full flex lg:items-center lg:justify-center h-full`}>
                            <div className={`w-full lg:w-6/12 px-6 lg:px-0 `}>
                                <div className={`relative`}>
                                    <StaticImage
                                        src={`${filepath}ECCO_210709_TCRISTIANI_050_286.jpg`}
                                        alt={``}
                                    />
                                    {captionCeramicLeather2}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-6 mt-20 lg:mt-40`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_120_USINE_USINE_436.jpg`}
                            alt={``}
                        />
                        <p className={`${constants.caption} pt-2 lg:pt-2 px-2 lg:px-4 w-10/12 lg:w-full`}>
                            Our milling drum. Tossing and turning the leather controlled with set humidity and temperature. This way we creates a pebble effect – a distinctly designed wave and ripple - on the grain. Like our unique to the
                            world Ceramic Leather. ​
                        </p>
                    </div>
                    <div className={`col-start-4 col-end-13 lg:col-start-8 lg:col-end-12 mt-12 lg:mt-72 relative z-10`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_130_SCULPTURES_507.jpg`}
                            alt={``}
                        />
                        <p className={`${constants.caption} pt-2 lg:pt-2 px-0`}>
                            Cubism inspired
                        </p>
                    </div>
                    {/* <div className={`col-start-1 col-end-13 lg:col-start-7 lg:col-end-12 lg:pl-10 mt-12 lg:mt-0 px-6 lg:px-0`}>
            <div className={`text-[66px] mb-40 mt-32 text-right hidden lg:block`}>your way.</div>
            <div className={``}>
              <InlineVideo
                src="UTOPIA.mp4"
              />
              <p className={`${constants.caption} pt-1 lg:pt-2`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div> */}
                </Grid>
                <div className={`bg-[#C7317C] w-full px-6 lg:px-10 relative pt-20 lg:pt-40 pb-7 lg:pb-12 text-[#FCF2B1]`}>
                    <div className={`w-11/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>Connect ME</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-full lg:w-9/12`}>A side-dog-hook made for customizasion and multilayer stacking.</div>
                    </div>
                </div>
                <Grid className={`bg-[#E6D1D0] relative`}>
                    <div className={`lg:hidden col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 relative z-0`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_149-mobile.jpg`}
                            alt={``}
                        />
                    </div>
                    <div className={`hidden lg:block col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 w-full relative z-0`}>
                        <StaticImage
                            src={`${filepath}ECCO_210709_TCRISTIANI_010_MATIERES_149.jpg`}
                            alt={``}
                            className={`w-full`}
                        />
                    </div>
                    <div className={`col-start-2 col-end-13 lg:col-start-5 lg:col-end-13 pb-14 absolute z-10 top-0 lg:top-auto`}>
                        <div className={`relative`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_070_335ceramic.jpg`}
                                alt={``}
                            />
                            <div className={`${constants.caption} absolute pt-1 lg:pt-2 flex bottom-4 left-4 lg:left-auto right-auto lg:right-4 text-[#FCF1A8]`}>
                                <div className={`${captionNumber}`}>6</div>
                                <div>ECCO Ceramic Crossbody in Shocking Pink/Cloud</div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <div className={`bg-[#182A1F] w-full px-6 lg:px-10 relative pt-20 lg:pt-40 pb-8 lg:pb-12 text-[#FCF1A8]`}>
                    <div className={`w-10/12 lg:w-6/12`}>
                        <div className={`${title3} mb-2 lg:mb-14 leading-none`}>Playful Roots</div>
                        <div className={`text-[14px] lg:text-[28px] leading-tight w-full lg:w-8/12`}>Sculptural embellishments made from upcycled ECCO shoelaces.​</div>
                    </div>
                </div>
                <Grid className={`bg-[#182A1F]`}>
                    <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 pb-12 lg:pb-0`}>
                        <div className={`relative`}>
                            <StaticImage
                                src={`${filepath}ECCO_210709_TCRISTIANI_110_378.jpg`}
                                alt={``}
                            />
                            <div className={`absolute text-[10px] lg:text-[16px] leading-none top-4 lg:top-auto right-4 lg:bottom-4 lg:right-6 text-[#FCF1A8]`}>7<span className={`pl-2`}>ECCO Lace Round Pack in Cloud</span></div>
                        </div>
                    </div>
                </Grid>

                <div className={`bg-[#182A1F] text-[#FCF1A8] text-[14px] lg:text-[22px] !leading-tight pt-0 lg:pt-40 pb-16 lg:pb-40 flex flex-col lg:flex-row w-full`}>
                    <div className={`w-full lg:w-5/12 mb-6 lg:mb-0`}>
                        <div className={`px-6 lg:px-10 lg:text-[32px]`}>
                            — Index
                        </div>
                    </div>
                    <div className={`w-10/12 lg:w-7/12 flex`}>
                        <div className={`px-6 lg:px-10 lg:flex lg:gap-8`}>
                            <div className={`w-full lg:w-2/6`}>
                                1. ECCO Hybrid Pot Bag in Shocking Pink
                                ECCO Pot Bag Mini in Light Sky
                                ECCO Circular Patch Mini Pot in Sea Port/Cloud/Shocking Pink
                                <br/><br/>
                                2. ECCO Circular Patch Mini Pot in Kombu Green/Soft Mint/Cloud / cloud
                                ECCO Dreamer Patch Mini in Kombu Green/Soft Mint/Cloud
                            </div>
                            <div className={`w-full lg:w-2/6 mt-5 lg:mt-0`}>
                                3. ECCO Cassette Bag Mini in Festival Fuchsia
                                ECCO Cassette Bag Compact in Pale Banana
                                ECCO Cassette Bag Full Size in Festival Fuchsia
                                <br/><br/>
                                4. ECCO Bento Pack in Seaport
                                <br/><br/>
                                5. ECCO Ceramic Tech-Bag Compact in Black
                            </div>
                            <div className={`w-full lg:w-2/6 mt-5 lg:mt-0`}>
                                6. ECCO Ceramic Crossbody in Shocking Pink/Cloud
                                <br/><br/>
                                7. ECCO Lace Round Pack in Cloud
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`w-full h-full relative text-[#1A2C39] bg-usine-pattern`}>
                    <div className={`px-6 lg:px-10 w-full h-full text-[14px] lg:text-[22px] leading-tight`}>
                        <div className={`flex lg:justify-center lg:items-center h-full`}>
                            <div className={`w-full lg:w-10/12 bg-[#E5E2C9] lg:flex px-6 lg:px-20 py-8 lg:py-40 lg:space-x-20 my-14 lg:my-40`}>
                                <div className={`w-full lg:w-6/12`}>
                                    <div className={`text-[24px] lg:text-[32px] mb-8 lg:mb-12`}>
                                        Introducing Tools To Live Freely
                                        ECCO Leather Goods, Season 2 SS22
                                    </div>
                                    <div className={``}>
                                        For our second season ECCO Leather Goods presents Tools To Live Freely, building on our brand’s debut with functional and playful accessories for optimistic New Utopians.
                                        <br/><br/>
                                        Believing that the future of leather goods is in purposeful tools that let us live our lives freely, the collection has plenty of colorful, customisable, and handsfree pieces that can be combined in
                                        unexpected ways to suit the wearer’s lifestyle.
                                        <br/><br/>
                                        The iconic Pot Bag, inspired by a nourishing tool – the watering can – is recast in a sensuous ceramic-effect leather, and available in a mini size that's perfect for a single precious item like
                                        lipstick or keys. New styles such as the the Bento Pack have a dual functionality as a hand-bag or backpack.
                                    </div>
                                </div>
                                <div className={`w-full lg:w-6/12 lg:flex lg:flex-col lg:space-y-16 mt-5 lg:-mt-12 pb-6 lg:pb-0`}>
                                    <div className={`w-full hidden lg:block`}>
                                        <div className={`flex justify-center items-center`}>
                                            <Hand
                                                className={`fill-[#C7317C] w-[245px]`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        Whilst still highly functional this second season buzzes with playful details and effervescent soda colors like Shocking Pink, Soft Mint, Pale Banana and Festival Fuchsia. Organic shaped patches make
                                        good use of leather off-cuts, quilted together or dangling like jewellery. And upcycled shoelaces from the motherbrand get a major second life.
                                        <br/><br/>
                                        We are determined to explore its Danish shoe design roots. The result is a completely fresh, yet authentic mix of inspirations that feels playful, colorful and happy to be alive.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SS22Landing
